
const { project_metadata, project_config, project_verification } = require("~/inc/config/project");
const lodash = require("lodash"); const moment = require("moment-timezone");
export default {
    head() {
        return {
            title: this.current_page_title,
            htmlAttrs: { lang: this.language_locale },
            meta: [
                ...[
                    { charset: 'utf-8' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=5' },
                    { name: 'format-detection', content: 'telephone=no' },
                    { hid: "apple-mobile-web-app-capable", name: 'apple-mobile-web-app-capable', content: 'yes' },
                    { hid: "apple-mobile-web-app-title", name: 'apple-mobile-web-app-title', content: project_metadata.name },
                    { hid: "application-name", name: 'application-name', content: project_metadata.project_name },
                    { hid: "msapplication-TileImage", name: 'msapplication-TileImage', content: '/logos/mstile-150x150.png' },
                    { hid: "msapplication-TileColor", name: 'msapplication-TileColor', content: '#1E61F0' },
                    { hid: "theme-color", name: 'theme-color', content: '#eef9ff' },
                    { hid: 'referrer', name: 'referrer', content: 'strict-origin-when-cross-origin' },
                    { hid: 'author', name: 'author', content: project_metadata.name },
                    { name: 'copyright', content: project_metadata.name },
                    { hid: 'robots', name: 'robots', content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' },
                    { name: 'googlebot', content: 'index, follow' },
                    { name: 'bingbot', content: 'index, follow' },
                    { name: 'yandex', content: 'index, follow' },
                    { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                    { hid: 'og:type:primary', property: 'og:type', content: 'website' },
                    { name: 'type', content: 'website' },
                    { name: 'generator', content: project_config.generator },
                    { hid: "og:url", property: "og:url", content: this.absolute_url },
                    { hid: "twitter:url", name: "twitter:url", content: this.absolute_url },

                    { hid: 'twitter:domain', name: 'twitter:domain', content: project_metadata.domain },
                    { hid: 'og:domain', name: 'og:domain', content: project_metadata.domain },
                    { hid: 'og:site_name', property: 'og:site_name', content: project_metadata.title },
                    { hid: "language", name: "language", content: this.language_locale },
                    { hid: "content-language", "http-equiv": "content-language", content: this.language_locale },
                    { hid: "og:locale", property: "og:locale", content: this.language_locale },
                    { hid: "og:published_time", property: "og:published_time", content: this.created_at },
                    { hid: "og:updated_time", property: "og:updated_time", content: this.updated_at },
                ],
                ...[
                    { hid: 'og:type:secondary', property: 'og:type', content: 'article' },
                ],
                ...project_verification.pinterest ? [
                    { hid: "pinterest-verification", name: "p:domain_verify", content: project_verification.pinterest },
                ] : [],
                ...project_verification.gsc ? [
                    { hid: "google-search-console-verification", name: 'google-site-verification', content: project_verification.gsc },
                ] : [],
                ...project_verification.bsc ? [
                    { hid: "bing-search-console-verification", name: 'msvalidate.01', content: project_verification.bsc },
                ] : [],
                ...this.twitter_details ? [
                    { hid: 'twitter:creator', name: 'twitter:creator', content: `@${this.twitter_details.author_username}` },
                    { hid: 'twitter:site', name: 'twitter:site', content: `@${this.twitter_details.site_username}` },
                    { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
                ] : [],
                ...this.current_page_title ? [
                    { hid: "title", name: "title", content: this.current_page_title },
                    { hid: "og:title", property: "og:title", content: this.current_page_title },
                    { hid: 'twitter:title', name: 'twitter:title', content: this.current_page_title },
                ] : [],
                ...this.current_page_description ? [
                    { hid: "description", name: "description", content: this.current_page_description },
                    { hid: "page:description", property: "description", content: this.current_page_description },
                    { hid: "og:description", property: "og:description", content: this.current_page_description },
                    { hid: 'twitter:description', name: 'twitter:description', content: this.current_page_description },
                ] : [],
                ...this.page_data?.keywords ? [
                    { hid: "keywords", name: "keywords", content: this.page_data?.keywords }
                ] : [],
                ...this.featured_image_details.url ? [
                    { hid: "og:image", property: "og:image", content: this.featured_image_details.url },
                    { hid: "og:image:secure_url", property: "og:image:secure_url", content: this.featured_image_details.url },
                    { hid: "og:image:alt", property: "og:image:alt", content: this.featured_image_details.alt },
                    { hid: 'og:image:width', property: 'og:image:width', content: this.featured_image_details.width },
                    { hid: 'og:image:height', property: 'og:image:height', content: this.featured_image_details.height },
                    { hid: "og:image:type", property: "og:image:type", content: this.featured_image_details.image_type },
                    { hid: "twitter:image", name: "twitter:image", content: this.featured_image_details.url },
                    { hid: "twitter:image:secure_url", name: "twitter:image:secure_url", content: this.featured_image_details.url },
                    { hid: "twitter:image:alt", name: "twitter:image:alt", content: this.featured_image_details.alt },
                    { hid: 'twitter:image:width', name: 'twitter:image:width', content: this.featured_image_details.width },
                    { hid: 'twitter:image:height', name: 'twitter:image:height', content: this.featured_image_details.height },
                    { hid: "twitter:image:type", name: "twitter:image:type", content: this.featured_image_details.image_type },
                ] : [],
            ],
            link: [
                { hid: "canonical", rel: "canonical", href: this.absolute_url },
                { rel: "apple-touch-icon", type: "image/png", sizes: "57x57", href: "/icons/apple-icon-57x57.png" },
                { rel: "apple-touch-icon", type: "image/png", sizes: "60x60", href: "/icons/apple-icon-60x60.png" },
                { rel: "apple-touch-icon", type: "image/png", sizes: "72x72", href: "/icons/apple-icon-72x72.png" },
                { rel: "apple-touch-icon", type: "image/png", sizes: "76x76", href: "/icons/apple-icon-76x76.png" },
                { rel: "apple-touch-icon", type: "image/png", sizes: "114x114", href: "/icons/apple-icon-114x114.png" },
                { rel: "apple-touch-icon", type: "image/png", sizes: "120x120", href: "/icons/apple-icon-120x120.png" },
                { rel: "apple-touch-icon", type: "image/png", sizes: "144x144", href: "/icons/apple-icon-144x144.png" },
                { rel: "apple-touch-icon", type: "image/png", sizes: "152x152", href: "/icons/apple-icon-152x152.png" },
                { rel: "apple-touch-icon", type: "image/png", sizes: "180x180", href: "/icons/apple-icon-180x180.png" },
                { rel: "icon", type: "image/png", sizes: "192x192", href: "/icons/android-icon-192x192.png" },
                { rel: "icon", type: "image/png", sizes: "32x32", href: "/icons/favicon-32x32.png" },
                { rel: "icon", type: "image/png", sizes: "96x96", href: "/icons/favicon-96x96.png" },
                { rel: "icon", type: "image/png", sizes: "16x16", href: "/icons/favicon-16x16.png" },
                { rel: "manifest", href: "/manifest.json" },
            ],
            script: [
                { "async": true, "src": 'https://getbenne.com/core.js', "data-site": "ow93kgnLOpWXQAPBm", "data-domain": "trywilder.com" },
            ],
        }
    },
    props: { page_data: { type: Object, default: null } },
    data() { return {} },
    computed: {
        created_at() {
            if (this.page_data && this.page_data?.created_at) {
                return moment.unix(this.page_data?.created_at).toISOString()
            }
            return project_metadata.created_at
        },
        updated_at() {
            if (this.page_data && this.page_data?.updated_at) {
                return moment.unix(this.page_data?.updated_at).toISOString()
            }
            return project_metadata.updated_at
        },
        absolute_url() {
            const full_url = project_metadata.base_url + this.$route.path
            return lodash.replace(full_url, /\/\//g, '/')
        },
        language_locale() {
            if (this.page_data?.language_code && this.page_data?.language_code !== 'en') {
                return `${this.page_data?.language_code}-${this.page_data?.language_code.toUpperCase()}`
            }
            return 'en-US'
        },
        current_page_title() {
            if (this.$route.path === '/') {
                return project_metadata.title
            }
            if (this.page_data?.page_title) {
                return `${this.page_data?.page_title} | ${project_metadata.title_template}`
            }
            var current_url_slug = this.$route.path.split('/').pop()
            var url_slug = lodash.replace(current_url_slug, '-', ' ')
            return `${lodash.startCase(url_slug)} | ${project_metadata.title_template}`
        },
        current_page_description() {
            if (this.$route.path === '/') {
                return project_metadata.description
            }
            if (this.page_data?.page_description) {
                return this.page_data?.page_description
            }
            var current_url_slug = this.$route.path.split('/').pop()
            var url_slug = lodash.replace(current_url_slug, '-', ' ')
            return `${lodash.startCase(url_slug)} page for ${project_metadata.name}. ${project_metadata.description}`
        },
        featured_image_details() {
            if (this.page_data?.featured_image?.url) {
                return {
                    url: this.page_data.featured_image.url,
                    width: this.page_data.featured_image.width,
                    height: this.page_data.featured_image.height,
                    alt: this.page_data.featured_image.alt,
                    image_type: this.page_data.featured_image.url.endsWith('.webp') ? 'image/webp' : this.page_data.featured_image.url.endsWith('.avif') ? 'image/avif' : 'image/png'
                }
            }
            return {
                url: project_metadata.featured_image.url,
                width: project_metadata.featured_image.width,
                height: project_metadata.featured_image.height,
                alt: project_metadata.featured_image.alt,
                image_type: project_metadata.featured_image.type
            }
        },
        twitter_details () {
            return null
        },
    },
    methods: {
    },
}
