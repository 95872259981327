const project_metadata = {
    name: "TryWilder",
    title: 'Notion to SEO-optimised, blazingly fast website in minutes. Free SSL. Detailed analytics, lead generation forms and a lot more.',
    title_template: 'TryWilder - Notion to SEO-optimised website in minutes.',
    description: 'Leverage your existing Notion content writing workflows. Write your blogs on Notion and watch them get automatically published on your SEO-friendly blog. No coding or design skills required.',
    featured_image: {
        url: "https://trywilder.com/images/social-share.png",
        width: 1200,
        height: 630,
        alt: "TryWilder - Notion to SEO-optimised website in minutes.",
        type: "image/png"
    },
    logo_url: 'https://trywilder.com/logos/trywilder.com.png',
    created_at: '2023-12-05T17:59:24+05:30',
    updated_at: '2023-12-05T11:23:15+05:30',
    base_url: "https://trywilder.com/",
    domain: "trywilder.com",
    project_url: "https://trywilder.com",
}

const project_config = {
    generator: "https://trywilder.com",
}

const project_verification = {
    pinterest: "5ba132c58a3381fe4ba6d368ce93eb48",
    gsc: "9c2UX6w0LjdSc_xohbYCwduO1u5UZTIBVkBXm3bT5mk"
}

module.exports = { project_metadata, project_config, project_verification }