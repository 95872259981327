
export default {
    async asyncData({ route, $axios, $content }) {
        const website_id = "159f4cbc-ee65-45ef-903d-dca2d2560f89"
        const page_data = (await $content(`${ website_id }/pages`, { deep: true })
            .where({ slug: "index" })
            .fetch())[0]
        if (!page_data || Object.keys(page_data).length === 0) { return { page_exists: false, } }
        return {
            page_data: page_data,
            page_title: page_data.title,
            page_description: page_data.description,
            page_created_at: page_data.publishAtDate,
            page_updated_at: page_data.updatedAtDate,
        }
    },
    data() {
        return {
            page_exists: true,
        }
    },
}
